<template>
  <v-container id="hnaccount-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      title="Hinet Account"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <v-dialog
          v-model="dialog_form"
          max-width="600px"
          @click:outside="close"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="ma-2"
              v-on="on"
              @click="createItem"
            >
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("hn-create") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.hnaccount.hn"
                      :label="$t('account')"
                      :disabled="editedIndex > -1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.hnaccount.password"
                      :label="$t('password')"
                      :disabled="editedIndex > -1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :label="$t('account_type')"
                      :items="account_type"
                      v-model="editedItem.hnaccount.type"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.hnaccount.owner"
                      :label="$t('owner')"
                      :disabled="editedIndex > -1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.hnaccount.tags"
                      :label="$t('tag')"
                      :disabled="editedIndex > -1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.hnaccount.memo"
                      :label="$t('memo')"
                      :disabled="editedIndex > -1"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions v-if="action != 'view'">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="secondary" dark class="ma-2" @click="updateAllBalance">
          <v-icon left>mdi-cloud-refresh</v-icon>
          {{ $t("refresh-balance") }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:header>
          <tr>
            <td>
              <v-text-field
                v-model="filterItem.hn"
                :label="$t('hn')"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-text-field>
            </td>
            <td>
              <v-select
                v-model="filterItem.type"
                :label="$t('type')"
                :items="type_items"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

            <td>
              <v-select
                v-model="filterItem.status"
                :label="$t('status')"
                :items="status_items"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
          </tr>
        </template>
        <template v-slot:item.type="{ item }">{{
          item.type | getText(type_items)
        }}</template>
        <template v-slot:item.query_balance="{ item }">{{
          item.query_balance
        }}</template>
        <template v-slot:item.max_balance="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.max_balance }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-text-field
                  v-model="item.max_balance"
                  :label="$t('max_balance')"
                  large
                ></v-text-field>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.low_bound_balance="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.low_bound_balance }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-text-field
                  v-model="item.low_bound_balance"
                  :label="$t('low_bound_balance')"
                  large
                ></v-text-field>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.current_amount="{ item }">{{
          item.current_amount
        }}</template>
        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.status="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(status_items) }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="status_items"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="updateBalance(item)">
                mdi-currency-usd</v-icon
              >
            </template>
            <span>{{ $t("update balance") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { account_type } from "@/definition.js";
export default {
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    editedItem: {
      hnaccount: {
        hn: "",
        password: "",
        max_balance: 0,
        current_amount: 0,
        query_balance: 0,
        owner: "",
        tags: "",
        memo: "",
        type: 0,
      },
    },
    defaultItem: {
      hn: "",
      password: "",
      max_balance: 0,
      current_amount: 0,
      query_balance: 0,
      owner: "",
      tags: "",
      memo: "",
    },
    filterItem: {
      hn: null,
      type: null,
      status: null,
    },
    account_type: account_type,
    editedIndex: -1,
    dialog_form: false,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("hn"),
        value: "hn",

        width: "15%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("type"),
        value: "type",
      },
      {
        sortable: false,
        text: vm.$i18n.t("memo"),
        value: "memo",
        width: "15%",
      },
      {
        sortable: true,
        text: "金額",
        value: "current_amount",
      },
      {
        sortable: false,
        text: vm.$i18n.t("group"),
        value: "group",
      },
      {
        sortable: false,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    type_items: account_type,
    status_items: [
      { text: "Invalid", value: 0 },
      { text: "Valid", value: 1 },
      { text: "InUse", value: 2 },
      { text: "LowBound", value: 3 },
    ],
  }),
  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("hnaccount");
    },
    query_quota() {
      if (this.editedItem != null) {
        return this.getQuota();
      }
      return 0;
    },
  },
  mounted() {
    // this.$options.sockets.onmessage = this.receiveSocketData;
  },
  beforeDestroy() {
    // delete this.$options.sockets.onmessage;
  },
  methods: {
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      console.log("Get the info", info);
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/hnaccounts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          task: this.id,
        },
      };
      if (sortBy.length > 0) {
        let array = [];
        let item = "";
        for (let i = 0; i < sortBy.length; i++) {
          let sortStr = sortBy[i].replace(".", "__");
          if (sortDesc[i]) {
            item = sortStr;
          } else {
            item = "-" + sortStr;
          }
          array.push(item);
        }
        config.params["ordering"] = array.join();
      }
      // console.log("getApi()  " + JSON.stringify(config.params));
      if (!this.$common_util.isEmpty(this.filterItem.type)) {
        config.params["type"] = this.filterItem.type;
      }
      if (!this.$common_util.isEmpty(this.filterItem.status)) {
        config.params["status"] = this.filterItem.status;
      }
      if (!this.$common_util.isEmpty(this.filterItem.hn)) {
        config.params["hn__startswith"] = this.filterItem.hn;
      }
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    postApi() {
      // create hn account
      console.log("create hn account");
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/hnaccounts/";
      let jsonData = {
        hn: this.editedItem.hnaccount.hn,
        password: this.editedItem.hnaccount.password,
        amount: this.editedItem.hnaccount.amount,
        type: this.editedItem.hnaccount.type,
        transcript_quota: this.editedItem.hnaccount.amount,
        query_quota: currentObj.getQuota(),
        owner: this.editedItem.hnaccount.owner,
        tags: this.editedItem.hnaccount.tags,
        memo: this.editedItem.hnaccount.memo,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log(jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getQuota() {
      let quota = 10 * this.editedItem.hnaccount.amount - 10;
      if (quota < 0) {
        return 0;
      } else {
        return quota;
      }
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    createItem() {
      this.action = "create";
    },
    close() {
      console.log("close me.");
      this.dialog_form = false;
      setTimeout(() => {
        this.hnaccount = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        this.patchApi(this.editedIndex);
        this.close();
      } else {
        this.postApi();
        this.close();
      }
      console.log("save");
    },
    onChange() {
      this.page = 1;
      this.getApi();
    },
    updateStatus(item) {
      console.log(
        "save " +
          item.id +
          " status" +
          item.status +
          " max_balance" +
          item.max_balance +
          " low_bound_balance" +
          item.low_bound_balance
      );
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/hnaccounts/" + item.id + "/";
      let jsonData = {
        status: item.status,
        max_balance: item.max_balance,
        low_bound_balance: item.low_bound_balance,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    updateBalance(item) {
      console.log("get the hn account current balance");
      console.log("create hn account");
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/hnaccounts/" +
        item.id +
        "/updateBalance/";
      let jsonData = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log(jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    updateAllBalance() {
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/hnaccounts/updateAllBalance/";
      let jsonData = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
};
</script>
